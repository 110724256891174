import React from 'react'

import {
  addNotification,
  Button,
  Inline,
  Notifications
} from '@te-digi/styleguide'

const NotificationsExample1 = () => (
  <>
    <Inline
      gap="md"
      wrap="wrap"
    >
      <Button
        color="secondary"
        onClick={() => {
          addNotification('Tämä on tavallinen ilmoitus.')
        }}
      >
        Tavallinen
      </Button>
      <Button
        color="success"
        onClick={() => {
          addNotification('Tämä on ilmoitus onnistuneesta tapahtumasta.', {
            status: 'success'
          })
        }}
      >
        Onnistunut
      </Button>
      <Button
        color="danger"
        onClick={() => {
          addNotification('Tämä on ilmoitus epäonnistuneesta tapahtumasta.', {
            status: 'error'
          })
        }}
      >
        Epäonnistunut
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          addNotification('Tämä on pysyvä ilmoitus.', {
            autoClose: false
          })
        }}
      >
        Pysyvä ilmoitus
      </Button>
      <Button
        color="dark"
        onClick={() => {
          addNotification('Tämä on piilotettu ilmoitus.', {
            hidden: true
          })
        }}
        variant="outline"
      >
        Piilotettu ilmoitus
      </Button>
    </Inline>
    <Notifications />
  </>
)

export { NotificationsExample1 }
